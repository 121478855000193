






































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import UserService from '@/services/UserService';

@Component
export default class ResetPassword extends mixins(Toasts) {
  protected password = '';
  protected passwordagain = '';
  protected secret = '';

  mounted(): void {
    this.secret = this.$route.query.secret.toString();
  }
  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }

  protected resetPassword(): void {
    this.setLoading(true);
    UserService.resetPassword(this.password, this.secret)
      .then(() => {
        this.setLoading(false);
        this.showToast('Password Reset', 'Your password has been reset.', 'success');
        this.$router.replace({ path: '/login' });
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast(
            'Failed to reset',
            'Could not reset password. ' + backendError.response.data.message,
            'danger'
          );
        }
      });
  }
}
